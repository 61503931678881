import './index.scss';

let isContentLoaded = false;

document.addEventListener("DOMContentLoaded", () => {
  isContentLoaded = true;
  addVideoSource();
});

const addVideoSource = () => {
  if (!isContentLoaded) {
    return;
  }
  const w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
  const isDesktop = w > 480;
  const videoId = `video-${isDesktop ? "desktop" : "mobile"}`;
  const videoSrc = `./video/${isDesktop ? "desktop" : "mobile"}.mp4`;
  const video = document.getElementById(videoId);
  if (video.children.length === 0) {
    console.log("Add video to: " + videoId);
    const source = document.createElement('source');
    source.src = videoSrc;
    source.type = 'video/mp4';
    video.appendChild(source);
  }
}

window.addEventListener("resize", addVideoSource);